import revive_payload_client_J1jToFMe4e from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@5.0.8_@capacitor+core@5.7.8__@parcel+watcher@2.4.1_@types+_ve5qsb67tscl5ihdpuoet7ym7i/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_6B66odDwGB from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@5.0.8_@capacitor+core@5.7.8__@parcel+watcher@2.4.1_@types+_ve5qsb67tscl5ihdpuoet7ym7i/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_f0azi7iLT9 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@5.0.8_@capacitor+core@5.7.8__@parcel+watcher@2.4.1_@types+_ve5qsb67tscl5ihdpuoet7ym7i/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_ql0DqH88Ki from "/vercel/path0/node_modules/.pnpm/@nuxtjs+supabase@1.2.2_magicast@0.3.4_rollup@4.21.0_webpack-sources@3.2.3/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import payload_client_tStzy8gP02 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@5.0.8_@capacitor+core@5.7.8__@parcel+watcher@2.4.1_@types+_ve5qsb67tscl5ihdpuoet7ym7i/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Yc6d4wcymS from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@5.0.8_@capacitor+core@5.7.8__@parcel+watcher@2.4.1_@types+_ve5qsb67tscl5ihdpuoet7ym7i/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Cg30RtuDSj from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@5.0.8_@capacitor+core@5.7.8__@parcel+watcher@2.4.1_@types+_ve5qsb67tscl5ihdpuoet7ym7i/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_0bk4BrjahE from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@5.0.8_@capacitor+core@5.7.8__@parcel+watcher@2.4.1_@types+_ve5qsb67tscl5ihdpuoet7ym7i/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_LB5mxLuMAT from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@4.21.0_typescript@5.5.4_vue@3.5.3_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/web/.nuxt/components.plugin.mjs";
import prefetch_client_cuFT1yagfn from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.4_@capacitor+preferences@5.0.8_@capacitor+core@5.7.8__@parcel+watcher@2.4.1_@types+_ve5qsb67tscl5ihdpuoet7ym7i/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/web/.nuxt/formkitPlugin.mjs";
import sentry_client_rCaV9InRPc from "/vercel/path0/packages/sentry/plugins/sentry.client.ts";
import mapbox_client_HMmMV6F7A6 from "/vercel/path0/web/plugins/mapbox.client.ts";
import vue_query_wrmMkNpEpe from "/vercel/path0/web/plugins/vue-query.ts";
export default [
  revive_payload_client_J1jToFMe4e,
  unhead_6B66odDwGB,
  router_f0azi7iLT9,
  supabase_client_ql0DqH88Ki,
  payload_client_tStzy8gP02,
  navigation_repaint_client_Yc6d4wcymS,
  check_outdated_build_client_Cg30RtuDSj,
  chunk_reload_client_0bk4BrjahE,
  plugin_vue3_LB5mxLuMAT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_cuFT1yagfn,
  formkitPlugin_pZqjah0RUG,
  sentry_client_rCaV9InRPc,
  mapbox_client_HMmMV6F7A6,
  vue_query_wrmMkNpEpe
]